@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: black;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @apply md:text-6xl text-[40px] font-bold leading-[1.25];
}

h2 {
  @apply md:text-6xl text-3xl font-bold leading-[1.25];
}

h3 {
  @apply md:text-4xl text-3xl font-bold leading-[1.25];
}

.green-text {
  @apply text-[#17FF76];
}
