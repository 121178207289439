.about-text {
    @apply w-full max-w-5xl text-lg relative md:pr-4;

    &:before {
        @apply absolute content-[''] w-[45px] h-[45px] bg-contain bg-[url('../../images/zigzag.svg')] top-[-7%] sm:top-[-5%] md:left-[-6%] left-[-8%];
    }

    &:after {
        @apply absolute lg:content-[''] w-[30px] h-[30px] bg-contain bg-[url('../../images/rhombus-violet.svg')] top-[10%] right-[-2%];
    }
}
