.quote {
    @apply relative;

    &:before {
        @apply absolute content-[''] w-[17px] h-[17px] bg-contain bg-[url('../../images/corner.svg')] top-[-1.5rem] left-[-1.5rem];
    }
    &:after {
        @apply absolute content-[''] w-[17px] h-[17px] bg-contain bg-[url('../../images/corner.svg')] rotate-90 top-[-1.5rem] right-[-1.5rem];
    }
}

.empty-element {
    @apply w-full relative;

    &:before {
        @apply absolute content-[''] w-[17px] h-[17px] bg-contain bg-[url('../../images/corner.svg')] -rotate-90 bottom-[-1.5rem] left-[-1.5rem];
    }
    &:after {
        @apply absolute content-[''] w-[17px] h-[17px] bg-contain bg-[url('../../images/corner.svg')] rotate-180 bottom-[-1.5rem] right-[-1.5rem];
    }
}
