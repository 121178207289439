.main-banner {
    @apply bg-[url('../../images/banner-bg.png')] bg-cover bg-center md:pt-80 pt-60 md:pb-36 pb-20 md:pl-20 md:pr-16 sm:px-12 px-10 relative;

    &:before {
        @apply content-[''] bg-[url('../../images/rhombus-violet.svg')] bg-contain absolute w-[16px] h-[16px] top-[24%] left-[9%];
    }

    &:after {
        @apply content-[''] bg-[url('../../images/rhombus-violet.svg')] bg-contain absolute w-[35px] h-[35px] top-[16%] right-[10%];
    }
}

.citation {
    &:before {
        @apply content-[''] bg-[url('../../images/quotes.svg')] bg-contain absolute w-[58px] h-[44px] bottom-[-44px] left-[-68px];
    }

    &:after {
        @apply content-[''] bg-[url('../../images/quotes.svg')] bg-contain absolute w-[58px] h-[44px] top-[-44px] right-[-58px];
    }
}