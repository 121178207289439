.price-item {
    @apply grid gap-8;

    grid-template-rows: [row1-start] 84px [row1-end] 100px [last];

    &.special {
        @apply md:col-auto md:mt-0 mt-10 md:py-0 py-10 col-span-full;
        border: none;

        @media (max-width: 767px) {
            gap: 20px 30px;
            grid-template-rows: [row1-start] 84px [row1-end] 100px;
            grid-template-columns: [col1-start] 84px [col1-end] 200px;
            border-top: 1px solid #17FF76;
            border-bottom: 1px solid #17FF76;
        }
    }
}
